<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                </div>
                <vs-alert
                  id="alert"
                  v-if="this.emailError === false"
                  :active.sync="showDismissibleAlert"
                  closable
                  icon-pack="feather"
                  close-icon="icon-x"
                  color="success"
                >{{ message }}
                </vs-alert
                >
                <vs-alert
                  v-else
                  :active.sync="showDismissibleAlert"
                  closable
                  icon-pack="feather"
                  close-icon="icon-x"
                  color="danger"
                >{{ message }}
                </vs-alert
                >
                <br/>
                <form>
                  <vs-input
                    :danger="errors.first('email')"
                    :success="!errors.first('email') && user.email != ''"
                    val-icon-success="done"
                    val-icon-danger="error"
                    v-validate="'required|email|min:3'"
                    data-vv-validate-on="blur"
                    name="email"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    label-placeholder="Email"
                    v-model="user.email"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{ errors.first("email") }}</span>
                </form>
                <br/>
                <br/>
                <div class="flex flex-wrap justify-between pt-3">
                  <vs-button
                    type="border"
                    @click="login()"
                    class="px-4 w-full md:w-auto"
                  >Back To Login
                  </vs-button>
                  <vs-button
                    :disabled="!validateForm"
                    @click="recoverPassword"
                    class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  >Recover Password
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    data() {
      return {
        user: {
          email: ""
        },
        showDismissibleAlert: false,
        message: "",
        emailError: false
      };
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email != "";
      }
    },
    methods: {
      ...mapActions("users", ["forgotPassword"]),
      recoverPassword() {
        const payload = {
          userDetail: this.user
        };
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading();
            this.$store
              .dispatch("auth/forgotPwd", payload)
              .then(response => {
                this.emailError = false;
                this.showDismissibleAlert = true;
                this.message = response.data.message;
                this.$vs.loading.close();
              })
              .catch(error => {
                this.emailError = true;
                this.showDismissibleAlert = true;
                this.message = error.message;
                this.$vs.loading.close();
              });
          }
        });
      },
      login() {
        this.displayLoader();
        this.$router.push("/admin/login");
      },
      displayLoader() {
        this.$vs.loading();
        setTimeout(() => {
          this.$vs.loading.close();
        }, 1000);
      }
    }
  };
</script>
